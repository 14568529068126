import { PLFlag, GBFlag, DEFlag } from 'mantine-flagpack';
import polish from 'date-fns/locale/pl';
import english from 'date-fns/locale/en-GB';
import german from 'date-fns/locale/de';

const LANGUAGES = {
	PL: {
		name: 'Polski',
		flag: PLFlag,
		dateFnsLocale: polish,
	},
	GB: {
		name: 'English',
		flag: GBFlag,
		dateFnsLocale: english,
	},
	DE: {
		name: 'Deutsche',
		flag: DEFlag,
		dateFnsLocale: german,
	},
} as const;

export default LANGUAGES;
