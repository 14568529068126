import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const NativeNagivator: FC = () => {
	const navigate = useNavigate();

	useEffect(() => {
		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			if(Capacitor.getPlatform() === 'ios')
			Browser.close()
			
			navigate(event.url.replace(import.meta.env.VITE_APP_URL, ''));
		});
		App.addListener('backButton', (event) => {
			if (event.canGoBack) navigate(-1);
			else App.exitApp();
		});
	}, []);
	return null;
};
