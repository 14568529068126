import React, { useEffect } from 'react';
import { Loading } from '@/components/partials/loader/Loading';
import { useAuth } from '@/providers/AuthProvider';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface Props {
	unauthenticated?: boolean;
}

export const AdminLogOnClient: React.FC<Props> = ({ unauthenticated }) => {
	const { handleCompletedLogin, user, logout } = useAuth();

	const [searchParams] = useSearchParams();

	const navigate = useNavigate();

	useEffect(() => {
		const accessToken = searchParams.get('accessToken');
		const refreshToken = searchParams.get('refreshToken');

		if (!accessToken || !refreshToken) {
			navigate('/');
			return;
		}

		if (user) logout();

		handleCompletedLogin(accessToken, refreshToken);
		navigate('/');
	}, []);

	return <Loading fullHeight={unauthenticated} />;
};
