import { Client, Panelist, Role } from '@/types/user';
import { LoginValues } from '@/components/views/unauthenticated/login/ClientLoginForm';
import { PollsterLoginAttempt } from './PanelistApi';
import { AppError } from '@/providers/ContentProvider';
import { QueryFunctionContext } from '@tanstack/react-query';
import { GlobalSettings } from './AuthenticatedApi';
import { AffiliationType } from './AffiliationApi';
import authorizedHttpClient from './clients/httpClient';
import axios, { HttpStatusCode } from 'axios';

const httpClient = axios.create({
	baseURL: import.meta.env.VITE_API_URL,
	headers: {
		Accept: 'application/json',
		'Content-type': 'application/json; charset=UTF-8',
	},
	validateStatus: (status) => status < HttpStatusCode.Unauthorized,
});

abstract class UnauthenticatedApi {
	static registerPanelist = async (panelist: Partial<Panelist>) => {
		try {
			const response = await httpClient.post<Panelist | AppError>(
				`panelists/register`,
				panelist
			);

			return response;
		} catch (error) {
			console.error(error);
			return undefined;
		}
	};

	static registerClient = async (client: Partial<Client>) => {
		try {
			const response = await httpClient.post<Client | AppError>(
				`clients/register`,
				client
			);

			return response;
		} catch (error) {
			console.error(error);
			return undefined;
		}
	};

	static login = async ({ email, password }: LoginValues) => {
		const response = await httpClient.post(`auth/login`, {
			email,
			password,
		});

		return response;
	};

	static loginPollster = async ({ email, password }: LoginValues) => {
		const response = await httpClient.post<PollsterLoginAttempt>(
			`pollster_login_attempts`,
			{
				email,
				password,
			}
		);

		return response;
	};

	static refreshToken = async (refresh_token: string) => {
		const response = await httpClient.post('auth/refresh_token', {
			refresh_token,
		});

		return response;
	};

	static requestResetPassword = async (email: string, role: string) => {
		try {
			const response = await httpClient.post(`reset_password/create_request`, {
				email,
				redirectUrl: `${import.meta.env.VITE_APP_URL}${role}/set-password`,
			});

			return response;
		} catch (error) {
			console.error(error);
			return undefined;
		}
	};

	static resetPassword = async (token: string, password: string) => {
		const response = await httpClient.post(
			`reset_password/process`,
			{
				token,
				password,
			},
			{ validateStatus: () => true }
		);

		return response;
	};

	static resendValidationEmail = async (email: string) => {
		await httpClient.post(
			'panelists/resend_verification',
			{ email },
			{ validateStatus: () => true }
		);
	};

	static checkReferralCode = async ({
		queryKey,
	}: QueryFunctionContext<[string, string, Role]>) => {
		const response = await authorizedHttpClient.post<{ valid: true }>(
			queryKey[0],
			{
				referralCode: queryKey[1],
				type:
					queryKey[2] === Role.PANELIST
						? AffiliationType.PANELIST
						: AffiliationType.CLIENT,
			}
		);

		return response.data;
	};

	static getReferralSettings = async ({
		queryKey,
	}: QueryFunctionContext<[string]>) => {
		const response = await httpClient.get<
			Pick<
				GlobalSettings,
				| 'maxAffiliationsPerPanelist'
				| 'pointsForAffiliation'
				| 'pointsForAffiliatedPanelist'
				| 'maxAffiliationsPerClient'
				| 'discountForAffiliation'
			>[]
		>(queryKey[0]);

		return response.data[0];
	};
}
export default UnauthenticatedApi;
