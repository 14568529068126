import React, { useEffect } from 'react';
import { UnauthenticatedWrapper } from '@/components/partials/unauthenticatedWrapper/UnauthenticatedWrapper';
import { LandingPage } from '@/components/views/unauthenticated/landing/LandingPage';
import { ClientLoginForm } from '@/components/views/unauthenticated/login/ClientLoginForm';
import { RegisterForm } from '@/components/views/unauthenticated/register/RegisterForm';
import { ResetPassword } from '@/components/views/unauthenticated/resetPassword/ResetPassword';
import { SetNewPassword } from '@/components/views/unauthenticated/setNewPassword/SetNewPassword';
import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import { roleToAppPath } from '@/utils/utilities';
import { PollsterLoginForm } from '@/components/views/unauthenticated/login/PollsterLoginForm';
import { PanelistLoginForm } from '@/components/views/unauthenticated/login/PanelistLoginForm';
import { AdminLogOnClient } from '@/components/views/common/AdminLogOnClient';
import { Language } from '@/types/types';
import { useContent } from '@/providers/ContentProvider';
import { Capacitor } from '@capacitor/core';
import { Role } from '@/types/user';
import LANGUAGES from '@/configs/languages';

export const UnauthenticatedApp: React.FC = () => {
	const { setLanguage } = useContent();

	const [searchParams] = useSearchParams();

	useEffect(() => {
		let lang = searchParams.get('lang');
		if (!lang) return;

		lang = lang.toUpperCase();

		if (!!LANGUAGES[lang as Language]) setLanguage(lang as Language);
	}, []);

	return (
		<Routes>
			<Route
				path="/admin-log-on-client"
				element={<AdminLogOnClient unauthenticated />}
			/>

			{Object.values(Role).map((role, index) => (
				<Route
					key={index}
					path={roleToAppPath(role)}
					element={<UnauthenticatedWrapper role={role} />}
				>
					<Route
						index
						element={
							role === Role.PANELIST ? (
								<LandingPage />
							) : (
								<Navigate to={`/${roleToAppPath(role)}/login`} />
							)
						}
					/>
					<Route path="login" element={getLoginFormPerRole(role)} />
					{role !== Role.POLLSTER && (
						<Route path="register" element={<RegisterForm role={role} />} />
					)}
					<Route
						path="reset-password"
						element={<ResetPassword role={role} />}
					/>
					<Route
						path="set-password/:token"
						element={<SetNewPassword role={role} />}
					/>
					<Route
						path="*"
						element={<Navigate to={`/${roleToAppPath(role)}`} />}
					/>
				</Route>
			))}
			<Route
				path="*"
				element={
					<Navigate
						to={`/${roleToAppPath(
							Capacitor.isNativePlatform() ? Role.PANELIST : Role.CLIENT
						)}`}
					/>
				}
			/>
		</Routes>
	);
};

function getLoginFormPerRole(role: Role) {
	switch (role) {
		case Role.CLIENT:
			return <ClientLoginForm />;
		case Role.PANELIST:
			return <PanelistLoginForm />;
		case Role.POLLSTER:
			return <PollsterLoginForm />;
	}
}
