import React, { useEffect, useState } from 'react';
import { Button } from '@/components/partials/button/Button';
import { PasswordInput } from '@/components/partials/passwordInput/PasswordInput';
import { Notification } from '@/components/partials/notification/Notification';
import { useAuth } from '@/providers/AuthProvider';
import { LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Link, useSearchParams } from 'react-router-dom';
import { Role } from '@/types/user';
import { roleToAppPath } from '@/utils/utilities';
import { ErrorCodes, useContent } from '@/providers/ContentProvider';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import LogoNPB from '@/assets/images/logo.svg';
import styles from './LoginForm.module.scss';

export interface LoginValues {
	email: string;
	password: string;
	remember?: boolean;
}

const baseSlug = 'panelist_login_';

export const PanelistLoginForm: React.FC = () => {
	const { getText, getContent } = useContent();

	const { login } = useAuth();

	const [searchParams, setSearchParams] = useSearchParams();
	const [errorStatus, setErrorStatus] = useState(0);

	const [verifyAccountMessage, setVerifyAccountMessage] = useState<0 | 1 | 2>(
		0
	);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const verifySuccessMessage = searchParams.get('success');
		const verifyErrorMessage = searchParams.get('error');

		if (!verifySuccessMessage && !verifyErrorMessage) return;

		if (verifySuccessMessage && verifyErrorMessage) {
			setSearchParams({});
			return;
		}

		setVerifyAccountMessage(verifySuccessMessage ? 1 : 2);
		setSearchParams({});
	}, []);

	const form = useForm({
		initialValues: {
			email: '',
			password: '',
			remember: true,
		},
	});

	const handleEmailResend = async () => {
		setLoading(true);
		await UnauthenticatedApi.resendValidationEmail(form.values.email);
		setLoading(false);
		setErrorStatus(0);
	};

	const handleSubmit = async (loginValues: LoginValues) => {
		setVerifyAccountMessage(0);
		setErrorStatus(0);
		setLoading(true);
		const result = await login(loginValues, Role.PANELIST);

		if (!!result) {
			setLoading(false);
			setErrorStatus(result);
			form.setFieldValue('password', '');
		}
	};

	return (
		<form className={styles.form} onSubmit={form.onSubmit(handleSubmit)}>
			<LoadingOverlay visible={loading} />
			<img src={LogoNPB} alt="NPB logo" />
			<h1>{getText(`${baseSlug}header`)}</h1>
			{!!verifyAccountMessage && (
				<Notification
					theme={verifyAccountMessage === 1 ? 'success' : 'error'}
					content={
						<p>
							{verifyAccountMessage === 1
								? getText(`${baseSlug}messageVerifySuccess`)
								: getText(`${baseSlug}messageVerifyFail`)}
						</p>
					}
				/>
			)}
			{!!errorStatus && (
				<Notification
					theme="error"
					content={
						errorStatus === ErrorCodes.USER_NOT_VERIFIED ? (
							<p>
								To konto nie zostało jeszcze aktywowane. Jeżeli na Twoją
								skrzynkę nie dotarła wiaodmość aktywacyjna naciśnij{' '}
								<Link onClick={handleEmailResend} to="">
									tutaj
								</Link>{' '}
								a wyślemy Ci kolejną
							</p>
						) : (
							<p>{getText(`${baseSlug}messageWrongCredentials`)}</p>
						)
					}
				/>
			)}
			<label htmlFor="email">Email</label>
			<input
				id="email"
				type="email"
				className={errorStatus === 1 ? styles.inputWrongCredentials : ''}
				{...form.getInputProps('email')}
				required
				placeholder="Wpisz adres email"
			/>
			<PasswordInput
				{...form.getInputProps('password')}
				error={errorStatus === 1}
				mb={32}
			/>
			<div className={`checkbox-container ${styles.checkbox}`}>
				<div className="input-container">
					<input
						id="remember"
						type="checkbox"
						{...form.getInputProps('remember', { type: 'checkbox' })}
					/>
				</div>
				<label className="checkbox-label" htmlFor="remember">
					{getText(`${baseSlug}checkboxLabel`)}
				</label>
			</div>
			<Button
				themes={['blue', 'arrow']}
				buttonProps={{
					type: 'submit',
				}}
			>
				<p>{getText(`${baseSlug}loginButton`)}</p>
			</Button>
			{getContent(`${baseSlug}information1`, {
				$resetPassword: `/${roleToAppPath(Role.PANELIST)}/reset-password`,
				$register: `/${roleToAppPath(Role.PANELIST)}/register`,
			})}
			{getContent(`${baseSlug}information2`, {
				$landing: `/${roleToAppPath(Role.PANELIST)}`,
			})}
		</form>
	);
};
