import { Cross, Research, ResearchGroup } from '@/types/research';
import { Panelist } from '@/types/user';
import { QueryFunctionContext } from '@tanstack/react-query';
import { HydraResponse } from '@/types/types';
import httpClient from './clients/httpClient';
import APP_CONFIG from '@/configs/appConfig';

export enum QuestionnaireStatus {
	PENDING,
	COMPLETED,
	QUOTAFULL,
	SCREENOUT,
	DROPOUT,
	TIMEOUT,
	EXCLUDED,
	EXPIRED,
	QUAL,
}

export interface Questionnaire {
	id: string;
	panelist: Panelist;
	research: Research;
	researchGroup: ResearchGroup;
	status: QuestionnaireStatus;
	startedAt: string;
	finishedAt: string;
	points: number;
}

export enum QuestionnaireResult {
	COMPLETED = 'completed',
	SCREENOUT = 'screenout',
	QUOTAFULL = 'quotafull',
	QUAL = 'qual',
}

abstract class QuestionnaireApi {
	static getAll = async ({ queryKey }: QueryFunctionContext<[string]>) => {
		const response = await httpClient.get<Questionnaire[]>(queryKey[0], {
			params: {
				pagination: false,
			},
		});

		return response.data;
	};

	static getPaginated = async ({
		pageParam,
		queryKey,
	}: QueryFunctionContext<[string], number>): Promise<Questionnaire[]> => {
		const response = await httpClient.get(queryKey[0], {
			params: {
				itemsPerPage: APP_CONFIG.INFINITE_SCROLL_PER_PAGE,
				page: pageParam,
			},
		});
		return response.data;
	};

	static getOne = async ({
		queryKey,
	}: QueryFunctionContext<[string, string]>): Promise<Questionnaire> => {
		return await (
			await httpClient.get(`${queryKey[0]}/${queryKey[1]}`)
		).data;
	};

	static redirect = async (id: string) => {
		const response = await httpClient.get<{
			questionnaireUrl: string;
		}>(`questionnaires/${id}/get_url`);

		return response.data.questionnaireUrl.trim();
	};

	static decodeResult = async (token: string) => {
		const response = await httpClient.post<{
			points: number;
			result: QuestionnaireResult;
			cross?: Cross;
		}>('panelists/verify_questionnaire', {
			token,
		});

		return response.data;
	};

	static getCount = async ({
		queryKey,
	}: QueryFunctionContext<[string, string]>) => {
		const response = await httpClient.get<HydraResponse<Questionnaire>>(
			queryKey[0],
			{
				headers: { Accept: 'application/ld+json' },
				params: {
					itemsPerPage: 0,
					page: 1,
				},
			}
		);

		return response.data['hydra:totalItems'];
	};
}

export default QuestionnaireApi;
