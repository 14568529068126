import React from 'react';
import { Outlet } from 'react-router-dom';
import { Role } from '@/types/user';
import { useContent } from '@/providers/ContentProvider';
import { roleToContentPath } from '@/utils/utilities';
import { Affix } from '@mantine/core';
import { LanguagesSelect } from '../navbar/desktopNavButtons/LanguagesSelect';
import APP_CONFIG from '@/configs/appConfig';
import styles from './UnauthenticatedWrapper.module.scss';

interface Props {
	role: Role;
}

export const UnauthenticatedWrapper: React.FC<Props> = ({ role }) => {
	const { getImage } = useContent();

	return (
		<>
			<main className={styles.wrapper}>
				<Outlet />
				<figure id="landing-hero" className={styles.heroImg}>
					<img
						src={getImage(`${roleToContentPath(role)}_unathenticatedImage`)}
						alt="Happy person, background in the shape of Polish borders"
					/>
				</figure>
			</main>
			{role === Role.CLIENT && (
				<Affix
					position={{
						right: 20,
						bottom: 20,
					}}
					zIndex={APP_CONFIG.Z_INDEX.SCROLL_TOP_BTN}
				>
					<LanguagesSelect
						selectProps={{
							transitionProps: {
								transition: 'slide-up',
							},
						}}
						nativeSelectProps={{
							w: 70,
						}}
					/>
				</Affix>
			)}
		</>
	);
};
