import React, { useState } from 'react';
import { TextInput, TextInputProps } from '@mantine/core';
import { useId } from '@mantine/hooks';
import APP_CONFIG from '@/configs/appConfig';
import styles from './PasswordInput.module.scss';

export const PasswordInput: React.FC<TextInputProps> = ({
	label,
	placeholder,
	...inputProps
}) => {
	const id = useId();

	const [passwordVisible, setPasswordVisible] = useState(false);
	const changePasswordVisibility = () => setPasswordVisible((prev) => !prev);

	return (
		<>
			<label htmlFor={id}>{label || 'Hasło'}</label>
			<div className={styles.container}>
				<TextInput
					id={id}
					required
					maxLength={APP_CONFIG.MAX_PASSWORD_LENGTH}
					placeholder={placeholder || 'Wpisz hasło'}
					type={passwordVisible ? 'text' : 'password'}
					{...inputProps}
				/>
				<button
					type="button"
					className={`${styles.toggler} ${
						passwordVisible ? styles.togglerActivated : ''
					}`}
					onClick={changePasswordVisibility}
				/>
			</div>
		</>
	);
};
