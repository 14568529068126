import React, {
	MouseEventHandler,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Notification } from '@/components/partials/notification/Notification';
import { Role } from '@/types/user';
import { timeline } from '@/utils/gsap';
import { useAuth } from '@/providers/AuthProvider';
import { useContent } from '@/providers/ContentProvider';
import { roleToAppPath, stripHTML } from '@/utils/utilities';
import { useScrollLock } from '@mantine/hooks';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import APP_CONFIG from '@/configs/appConfig';
import LogoNPB from '@/assets/images/logo.svg';
import Icons from '@/assets/Icons';
import styles from './LandingPage.module.scss';

const START_STYLES = {
	'clip-path': 'polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)',
	opacity: 0,
	y: 50,
};

const baseSlug = `panelist_landing_`;

export const LandingPage: React.FC = () => {
	const anchorRef = useRef<HTMLAnchorElement>(null!);

	const { getText, getContent } = useContent();

	const [searchParams] = useSearchParams();

	const [errorMessage, setErrorMessage] = useState('');

	const { handleCompletedLogin } = useAuth();

	const [, setScrollLocked] = useScrollLock(true);

	useLayoutEffect(() => {
		if (!searchParams.toString()) {
			const heroImg = document.getElementById('landing-hero');
			if (heroImg) heroImg.style.opacity = '0';
			timeline()
				.from(`h1, .${styles.logo}`, {
					...START_STYLES,
					duration: 2.2,
				})
				.from(
					`.${styles.option}`,
					{
						...START_STYLES,
						stagger: 0.2,
					},
					'-=2'
				)
				.to('figure', { opacity: 1 }, '-=1.5')
				.from(
					`.${styles.register}`,
					{
						...START_STYLES,
					},
					'-=1.8'
				);

			setTimeout(() => {
				setScrollLocked(false);
			}, 3000);
		}

		return () => setScrollLocked(false);
	}, []);

	useEffect(() => {
		const accessToken = searchParams.get('accessToken');
		const refreshToken = searchParams.get('refreshToken');
		const message = searchParams.get('message');
		const isRedirect = searchParams.get('redirect');

		if (message) {
			setErrorMessage(stripHTML(message));
			return;
		}

		if (!accessToken || !refreshToken) return;

		if (!isRedirect) {
			anchorRef.current.href = `${import.meta.env.VITE_APP_URL}${roleToAppPath(
				Role.PANELIST
			)}?accessToken=${accessToken}&refreshToken=${refreshToken}&redirect=true`;
			anchorRef.current.click();
			return;
		}

		const success = handleCompletedLogin(accessToken, refreshToken);

		if (!success)
			setErrorMessage(
				'Otrzymany token jest niepoprawny! Spróbuj ponownie później'
			);
	}, [searchParams]);

	const handleSocialsClick: MouseEventHandler<HTMLAnchorElement> = async (
		e
	) => {
		if (!Capacitor.isNativePlatform()) return;

		e.preventDefault();

		await Browser.open({
			url: e.currentTarget.href,
			toolbarColor: APP_CONFIG.CAPACITOR_BROWSER_TOOLBAR_COLOR,
		});
	};

	return (
		<section className={styles.container}>
			<a href="" ref={anchorRef} className="hidden-element"></a>
			<img className={styles.logo} src={LogoNPB} alt="NPB logo" />
			<h1>{getText(`${baseSlug}header`)}</h1>
			{errorMessage && (
				<Notification content={<p>{errorMessage}</p>} theme="error" />
			)}
			<div className={styles.loginOptions}>
				<a
					href={`${import.meta.env.VITE_API_BASE_URL}social/facebook/login`}
					onClick={handleSocialsClick}
				>
					<div className={styles.option}>
						<div className={styles.circle}>{Icons.facebook} </div>
						<p>{getText(`${baseSlug}loginFacebookLabel`)}</p>
					</div>
				</a>
				<a
					href={`${import.meta.env.VITE_API_BASE_URL}social/google/login`}
					onClick={handleSocialsClick}
				>
					<div className={styles.option}>
						<div className={styles.circle}>{Icons.gmail}</div>
						<p>{getText(`${baseSlug}loginGmailLabel`)}</p>
					</div>
				</a>
				<a
					href={`${import.meta.env.VITE_API_BASE_URL}social/linkedin/login`}
					onClick={handleSocialsClick}
				>
					<div className={styles.option}>
						<div className={styles.circle}>{Icons.linkedIn} </div>
						<p>{getText(`${baseSlug}loginLinkedInLabel`)}</p>
					</div>
				</a>
				<Link to="login">
					<div className={styles.option}>
						<div className={styles.circle}>{Icons.mail}</div>
						<p>{getText(`${baseSlug}loginEmailLabel`)}</p>
					</div>
				</Link>
			</div>
			<div className={styles.register}>
				{getContent('panelist_landing_information', {
					$register: `/${roleToAppPath(Role.PANELIST)}/register`,
				})}
			</div>
		</section>
	);
};
