import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/store/index';

interface SliceState {
	loading: boolean;
	errorCode: string;
	animationsFired: {
		questionnaires: boolean;
		preQuestionnaires: boolean;
		preQuestionnairesLinear: boolean;
	};
}

const initialState: SliceState = {
	loading: false,
	errorCode: '',
	animationsFired: {
		questionnaires: false,
		preQuestionnaires: false,
		preQuestionnairesLinear: false,
	},
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.errorCode = action.payload;
		},
		completeAnimation: (
			state,
			action: PayloadAction<keyof SliceState['animationsFired']>
		) => {
			state.animationsFired[action.payload] = true;
		},
	},
});

export const { setLoading, setErrorCode, completeAnimation } = appSlice.actions;

export const selectAppState = (state: RootState) => state.app;
export default appSlice.reducer;
