import {
	AnchorHTMLAttributes,
	ButtonHTMLAttributes,
	CSSProperties,
	ReactNode,
	forwardRef,
} from 'react';
import { Tooltip, TooltipProps } from '@mantine/core';
import { Link } from 'react-router-dom';
import Icons from '@/assets/Icons';
import styles from './Button.module.scss';

interface CommonProps {
	themes: (
		| 'blue'
		| 'light'
		| 'success'
		| 'warning'
		| 'arrow'
		| 'arrowReverse'
		| 'icon'
		| 'iconOnly'
		| 'edit'
		| 'blueFont'
		| 'hiddeTextMobile'
		| 'transparent'
		| 'fadeIn'
		| 'fullWidth'
	)[];
	children?: ReactNode;
	image?: string;
	size?: 'sm' | 'lg';
	tooltip?: string;
	tooltipProps?: TooltipProps;
	disabled?: boolean;
	displayNone?: boolean;
	wrapperStyles?: CSSProperties;
}

interface ButtonProps {
	isLink?: never;
	to?: never;
	buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
}

interface LinkProps {
	isLink: true;
	to: string;
	buttonProps?: AnchorHTMLAttributes<HTMLAnchorElement>;
}

type Props = CommonProps & (ButtonProps | LinkProps);

export const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
	const isLink = checkIfLink(props);

	const className = `${props.themes.map((style) => styles[style]).join(' ')} ${
		styles.button
	} ${props.size === 'sm' ? styles.small : ''} ${
		props.buttonProps?.className ? props.buttonProps?.className : ''
	} ${props.disabled ? styles.disabled : ''}`;

	const children = (
		<>
			{props.children}
			{props.themes.includes('edit') && Icons.edit}
		</>
	);

	if (props.displayNone) return null;

	return (
		<Tooltip
			label={props.tooltip}
			disabled={!props.tooltip}
			withArrow
			{...props.tooltipProps}
		>
			<div className={styles.tooltipWrapper} style={props.wrapperStyles}>
				{isLink ? (
					<Link {...props.buttonProps} className={className} to={props.to}>
						{children}
					</Link>
				) : (
					<button ref={ref} {...props.buttonProps} className={className}>
						{children}
					</button>
				)}
			</div>
		</Tooltip>
	);
});

function checkIfLink(props: ButtonProps | LinkProps): props is LinkProps {
	return !!props.isLink;
}
