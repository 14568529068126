import React, { useState } from 'react';
import { Button } from '@/components/partials/button/Button';
import { Notification } from '@/components/partials/notification/Notification';
import { PasswordInput } from '@/components/partials/passwordInput/PasswordInput';
import { useAuth } from '@/providers/AuthProvider';
import { LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Role } from '@/types/user';
import { roleToAppPath } from '@/utils/utilities';
import { useContent } from '@/providers/ContentProvider';
import LogoNPB from '@/assets/images/logo.svg';
import styles from './LoginForm.module.scss';

export interface LoginValues {
	email: string;
	password: string;
	remember?: boolean;
}

const baseSlug = 'client_login_';

export const ClientLoginForm: React.FC = () => {
	const { getText, getContent, getErrorMessage } = useContent();

	const [errorStatus, setErrorStatus] = useState<number>(0);

	const [loading, setLoading] = useState(false);

	const form = useForm({
		initialValues: {
			email: '',
			password: '',
			remember: true,
		},
	});

	const { login } = useAuth();

	const handleSubmit = async (loginValues: LoginValues) => {
		setErrorStatus(0);
		setLoading(true);
		const result = await login(loginValues, Role.CLIENT);

		if (!!result) {
			setLoading(false);
			setErrorStatus(result);
			form.reset();
		}
	};

	return (
		<form className={styles.form} onSubmit={form.onSubmit(handleSubmit)}>
			<LoadingOverlay visible={loading} />
			<img src={LogoNPB} alt="NPB logo" />
			<h1>{getText(`${baseSlug}header`)}</h1>
			{!!errorStatus && (
				<Notification
					theme="error"
					content={
						<p>
							{errorStatus === 1
								? getText(`${baseSlug}messageWrongCredentials`)
								: getErrorMessage(errorStatus)}
						</p>
					}
				/>
			)}
			<label>{getText(`${baseSlug}emailLabel`)}</label>
			<input
				className={errorStatus ? styles.inputWrongCredentials : ''}
				{...form.getInputProps('email')}
				type="email"
				required
				placeholder={getText(`${baseSlug}passwordPlaceholder`)}
			/>
			<PasswordInput
				{...form.getInputProps('password')}
				error={errorStatus === 1}
				mb={32}
				label={getText(`${baseSlug}passwordLabel`)}
				placeholder={getText(`${baseSlug}passwordPlaceholder`)}
			/>
			<div className={`checkbox-container ${styles.checkbox}`}>
				<div className="input-container">
					<input
						id="remember"
						type="checkbox"
						{...form.getInputProps('remember', { type: 'checkbox' })}
					/>
				</div>
				<label className="checkbox-label" htmlFor="remember">
					{getText(`${baseSlug}checkboxLabel`)}
				</label>
			</div>
			<Button
				themes={['blue', 'arrow']}
				buttonProps={{
					type: 'submit',
				}}
			>
				<p>{getText(`${baseSlug}loginButton`)}</p>
			</Button>
			{getContent(`${baseSlug}information`, {
				$resetPassword: `/${roleToAppPath(Role.CLIENT)}/reset-password`,
				$register: `/${roleToAppPath(Role.CLIENT)}/register`,
			})}
		</form>
	);
};
