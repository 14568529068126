import React, { useEffect, useState } from 'react';
import { Button } from '@/components/partials/button/Button';
import { Notification } from '@/components/partials/notification/Notification';
import { PasswordInput } from '@/components/partials/passwordInput/PasswordInput';
import { useAuth } from '@/providers/AuthProvider';
import { LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Role } from '@/types/user';
import { roleToAppPath } from '@/utils/utilities';
import { useContent } from '@/providers/ContentProvider';
import LogoNPB from '@/assets/images/logo.svg';
import Icons from '@/assets/Icons';
import styles from './LoginForm.module.scss';

const baseSlug = 'pollster_login_';

export const PollsterLoginForm: React.FC = () => {
	const { getText, getContent } = useContent();

	const { loginPollster, handleCompletedLogin } = useAuth();

	const [loading, setLoading] = useState(false);
	const [waitingForAccept, setWaitingForAccept] = useState(false);
	const [requestRejected, setRequestRejected] = useState(false);

	const [loginRequestId, setLoginRequestId] = useState('');

	const form = useForm({
		initialValues: {
			email: '',
			password: '',
		},
	});

	const [showError, setShowError] = useState(false);

	const handleSubmit = async () => {
		setShowError(false);
		setLoading(true);
		const attempt = await loginPollster(form.values);
		setLoading(false);
		if (attempt) {
			setLoginRequestId(attempt.id);
			setWaitingForAccept(true);
		} else {
			setShowError(true);
			form.reset();
		}
		form.reset();
	};

	useEffect(() => {
		if (loginRequestId) {
			const loginCheck = new EventSource(
				`${
					import.meta.env.VITE_API_URL
				}pollster_login_attempts/${loginRequestId}/check`
			);

			loginCheck.onerror = () => loginCheck.close;

			const listener = ({ data }: MessageEvent<string>) => {
				loginCheck.close();

				const { pollsterLoginCheck: payload } = JSON.parse(data);

				const { success, token, refresh_token } = payload;

				if (success) return handleCompletedLogin(token, refresh_token);

				setRequestRejected(true);
			};

			loginCheck.addEventListener('pollsterLoginCheck', listener);

			return () => {
				loginCheck.removeEventListener('pollsterLoginCheck', listener);
				loginCheck.close();
			};
		}
	}, [loginRequestId, handleCompletedLogin]);

	return (
		<form className={styles.form} onSubmit={form.onSubmit(handleSubmit)}>
			<LoadingOverlay visible={loading} />
			<img src={LogoNPB} alt="NPB logo" />
			<h1>{getText(`${baseSlug}header`)}</h1>
			{showError && (
				<Notification
					theme="error"
					content={<p>{getText(`${baseSlug}messageWrongCredentials`)}</p>}
				/>
			)}
			{waitingForAccept || requestRejected ? (
				requestRejected ? (
					<Notification
						theme="error"
						content={<p>{getText(`${baseSlug}messageRejected`)}</p>}
					/>
				) : (
					<Notification
						theme="informative"
						content={<p>{getText(`${baseSlug}messageWaitForAccept`)}</p>}
						icon={Icons.user}
					/>
				)
			) : (
				<>
					<label htmlFor="email">Email</label>
					<input
						id="email"
						type="email"
						required
						className={showError ? styles.inputWrongCredentials : ''}
						{...form.getInputProps('email')}
						placeholder="Wpisz adres email"
					/>
					<PasswordInput
						{...form.getInputProps('password')}
						error={showError}
						mb={32}
					/>
					<Button
						themes={['blue', 'arrow']}
						buttonProps={{
							type: 'submit',
						}}
					>
						<p>{getText(`${baseSlug}loginButton`)}</p>
					</Button>
					{getContent(`${baseSlug}information`, {
						$resetPassword: `/${roleToAppPath(Role.POLLSTER)}/reset-password`,
					})}
				</>
			)}
		</form>
	);
};
