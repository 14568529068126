import { createRoot } from 'react-dom/client';
import { AppProviders } from '@/providers/AppProviders';
import { App } from '@/components/routes/App';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import './sass/global.scss';

if (import.meta.env.PROD) disableReactDevTools();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<AppProviders>
		<App />
	</AppProviders>
);
