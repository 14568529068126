import { QueryFunctionContext } from '@tanstack/react-query';
import {
	HydraFormattedResponseData,
	HydraQueryOptions,
	HydraResponse,
} from '@/types/types';
import { Panelist, Pollster } from '@/types/user';
import { QueryKey } from '@/configs/queryKeys';
import APP_CONFIG from '@/configs/appConfig';
import httpClient from './clients/httpClient';

export enum ConversationStatus {
	NEW,
	SUCCESS,
	MISSED,
	UNAVAILABLE,
	WRONG_NUMBER,
	WRONG_PERSON,
	EXPIRED,
}
export interface Conversation {
	id: string;
	panelist: Panelist;
	pollster: Pollster;
	note: string;
	status: ConversationStatus;
	inProgress: boolean;
	finishedAt: string;
}

abstract class ConversationApi {
	static create = async ({
		panelist,
		pollster,
	}: {
		panelist: string;
		pollster: string;
	}) => {
		const response = await httpClient.post<Conversation>(
			QueryKey.conversations.conversations,
			{
				panelist,
				pollster,
			}
		);

		return response.data;
	};

	static getInProgress = async ({
		queryKey,
	}: QueryFunctionContext<[string, HydraQueryOptions]>): Promise<
		HydraFormattedResponseData<Conversation>
	> => {
		const response = await httpClient.get<HydraResponse<Conversation>>(
			queryKey[0],
			{
				headers: { Accept: 'application/ld+json' },
				params: {
					inProgress: true,
					page: queryKey[1].activePage,
					itemsPerPage: APP_CONFIG.SIZE_PER_PAGE,
					[`order[${queryKey[1].sort?.key}]`]: queryKey[1].sort?.order,
				},
			}
		);
		return {
			data: response.data['hydra:member'],
			total: response.data['hydra:totalItems'],
		};
	};

	static getOne = async ({
		queryKey,
	}: QueryFunctionContext<[string, string]>) => {
		const response = await httpClient.get<Conversation>(
			`${queryKey[0]}/${queryKey[1]}`
		);
		return response.data;
	};

	static changeStatus = async ({
		status,
		id,
	}: {
		status: ConversationStatus;
		id: string;
	}) => {
		await httpClient.patch(
			`${QueryKey.conversations.conversations}/${id}/change_status`,
			{
				status,
			}
		);
	};

	static finishConversation = async (id: string) => {
		await httpClient.patch(
			`${QueryKey.conversations.conversations}/${id}/change_progress`,
			{
				inProgress: false,
			}
		);
	};

	static changeNote = async ({ note, id }: { note: string; id: string }) => {
		await httpClient.patch(
			`${QueryKey.conversations.conversations}/${id}/change_note`,
			{
				note,
			}
		);
	};

	static getHistory = async ({
		queryKey,
	}: QueryFunctionContext<[string, string]>) => {
		const response = await httpClient.get<Conversation[]>(
			QueryKey.conversations.conversations,
			{
				params: {
					panelist: queryKey[1],
					'order[createdAt]': 'desc',
					inProgress: false,
					pagination: false,
				},
			}
		);
		return response.data;
	};

	static finishAllByPollster = async (pollsterId: string) => {
		await httpClient.patch(
			`${QueryKey.pollsters}/${pollsterId}/end_conversations`,
			{
				pollsterId,
			}
		);
	};

	static getInProgressCount = async ({
		queryKey,
	}: QueryFunctionContext<[string, string]>) => {
		const response = await httpClient.get<HydraResponse<Conversation>>(
			queryKey[0],
			{
				headers: { Accept: 'application/ld+json' },
				params: {
					inProgress: true,
					page: 1,
					itemsPerPage: 0,
				},
			}
		);
		return response.data['hydra:totalItems'];
	};
}
export default ConversationApi;
