import React, { lazy, useEffect, useLayoutEffect } from 'react';
import { UnauthenticatedApp } from './UnauthenticatedApp';
import { useAuth } from '@/providers/AuthProvider';
import { useLocation } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { ErrorPage } from '@/components/views/authenticated/common/errorPage/ErrorPage';
import { Loading } from '@/components/partials/loader/Loading';
import { useDocumentTitle } from '@mantine/hooks';
import { useMetaDesc } from '@/utils/hooks/useMetaDesc';
import { RoleAppPath } from '@/types/rolePaths';
import { useModals } from '@mantine/modals';
import APP_CONFIG from '@/configs/appConfig';
import httpClient from '@/api/clients/httpClient';

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'));

const UNAUTHENTICATED_PATHS = [
	'login',
	'register',
	'set-password',
	'reset-password',
	'admin-log-on-client',
];

export const App: React.FC = () => {
	const dispatch = useTypedDispatch();

	const { pathname } = useLocation();

	const role = pathname.split('/')[1] as RoleAppPath;

	useDocumentTitle(getMetaTitle(role));
	useMetaDesc(getMetaDescription(role));

	const { user, setRedirectOnLogUrl } = useAuth();

	const { errorCode } = useTypedSelector((state) => state.app);

	const modals = useModals();
	useLayoutEffect(() => {
		window.scrollTo(0, 0);
		modals.closeAll();
	}, [pathname]);

	useEffect(() => {
		if (!user && !UNAUTHENTICATED_PATHS.includes(pathname.split('/')[2]))
			setRedirectOnLogUrl(pathname);
	}, [dispatch]);

	if (errorCode) return <ErrorPage httpCode={errorCode} />;

	if (
		user &&
		!httpClient.defaults.headers.common.Authorization &&
		!['/admin-log-on-client'].includes(pathname)
	)
		return <Loading fullHeight />;

	return user ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};

function getMetaTitle(role: RoleAppPath) {
	switch (role) {
		case RoleAppPath.PANELIST:
			return APP_CONFIG.META_TITLE.PANELIST;
		case RoleAppPath.CLIENT:
			return APP_CONFIG.META_TITLE.CLIENT;
		case RoleAppPath.POLLSTER:
			return APP_CONFIG.META_TITLE.POLLSTER;
		default:
			return APP_CONFIG.META_TITLE.GENERIC;
	}
}

function getMetaDescription(role: RoleAppPath) {
	switch (role) {
		case RoleAppPath.PANELIST:
			return APP_CONFIG.META_DESCRIPTION.PANELIST;
		case RoleAppPath.CLIENT:
			return APP_CONFIG.META_DESCRIPTION.CLIENT;
		case RoleAppPath.POLLSTER:
			return APP_CONFIG.META_DESCRIPTION.POLLSTER;
		default:
			return APP_CONFIG.META_DESCRIPTION.GENERIC;
	}
}
