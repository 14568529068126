export enum RoleAppPath {
	CLIENT = 'klient',
	PANELIST = 'panelista',
	POLLSTER = 'ankieter',
}

export enum RoleApiPath {
	CLIENT = 'clients',
	PANELIST = 'panelists',
	POLLSTER = 'pollsters',
}

export enum RoleContentPath {
	CLIENT = 'client',
	PANELIST = 'panelist',
	POLLSTER = 'pollster',
}
