import React, { ComponentPropsWithoutRef, FC, forwardRef } from 'react';
import {
	NativeSelect,
	NativeSelectProps,
	Select,
	SelectProps,
} from '@mantine/core';
import { useContent } from '@/providers/ContentProvider';
import { Language } from '@/types/types';
import { useMediaQuery } from '@mantine/hooks';
import LANGUAGES from '@/configs/languages';
import APP_CONFIG from '@/configs/appConfig';
import styles from './LanguagesSelect.module.scss';

interface ItemProps extends ComponentPropsWithoutRef<'div'> {
	value: Language;
}

interface Props {
	selectProps?: Partial<SelectProps>;
	nativeSelectProps?: Partial<NativeSelectProps>;
}

export const LanguagesSelect: FC<Props> = ({
	selectProps,
	nativeSelectProps,
}) => {
	const mobileBreakpoint = useMediaQuery(
		`(max-width: ${APP_CONFIG.BREAKPOINTS.MOBILE}px)`
	);

	const { language, setLanguage } = useContent();

	const SelectedFlag = LANGUAGES[language].flag;

	const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
		({ value, ...others }, ref) => {
			const Flag = LANGUAGES[value].flag;

			return (
				<div ref={ref} className={styles.selectItem} {...others}>
					<Flag w={28} radius="xs" />
				</div>
			);
		}
	);

	return mobileBreakpoint ? (
		<div className={styles.nativeWrapper}>
			<NativeSelect
				data={Object.keys(LANGUAGES)}
				classNames={{
					input: styles.nativeSelectInput,
				}}
				onChange={(e) => setLanguage(e.currentTarget.value as Language)}
				value={language}
				rightSection={<></>}
				rightSectionWidth="0px"
				w={112}
				{...nativeSelectProps}
			/>
			<div className={styles.selectedNativeFlag}>
				<SelectedFlag w={28} radius="sm" />
			</div>
		</div>
	) : (
		<div className={styles.wrapper}>
			<div className={styles.selectedFlag}>
				<SelectedFlag w={28} radius="sm" />
			</div>
			<Select
				value={language}
				data={Object.keys(LANGUAGES).filter((lang) => lang !== language)}
				itemComponent={SelectItem}
				onChange={setLanguage}
				classNames={{
					dropdown: styles.selectDropdown,
					input: styles.selectInput,
					item: styles.selectItem,
					rightSection: styles.selectRightSection,
					itemsWrapper: styles.selectItemsWrapper,
				}}
				{...selectProps}
			/>
		</div>
	);
};
